// import 'core-js/stable'
import { isIE } from 'react-device-detect'
import React, { useEffect, useRef } from 'react'
import SEO from '../components/common/seo.js'
import Layout from '../components/common/layout.js'
import Navigation from '../components/navigation/navigation.js'
import Header from '../components/sections/header.js'
import News from '../components/sections/news/news.js'
import About from '../components/sections/about.js'
import Team from '../components/sections/team/team.js'
import Services from '../components/services/services.js'
//import Partners from '../components/sections/partners.js'
import Career from '../components/sections/career.js'
import NonAvailable from '../components/sections/nonAvailable.js'

import Map from '../components/sections/map.js'
import Contact from '../components/sections/contact.js'
import Cookies from '../components/common/cookies.js'
import ScrollTrigger from 'gsap/ScrollTrigger'

import gsap from 'gsap'
//import References from '../components/sections/references/References.js'
gsap.registerPlugin(ScrollTrigger)
const IndexPage = () => {
    let sections = useRef(null)
        // useEffect(() => {
        // 	let sectionArray = sections.current.querySelectorAll('section')

    // 	sectionArray.forEach((section) => {
    // 		gsap.fromTo(
    // 			section.children,
    // 			{
    // 				x: -15,
    // 				opacity: 0,
    // 			},
    // 			{
    // 				duration: 1,
    // 				x: 0,
    // 				opacity: 1,
    // 				stagger: 0.5,
    // 				scrollTrigger: {
    // 					trigger: section,
    // 					start: '0% 85%',
    // 					// markers: true,
    // 				},
    // 			}
    // 		)
    // 	})
    // }, [])
    return (
		// przenies strony z wylaczonestrony do katalogu page

		<Layout>
			<SEO title="Home" />

			<Navigation />

			<Header />

			<div ref={sections}>
				<News />
				<About />

				<Team />
                <Services />
				<Career />
			</div>

			<Map />
			<Contact />
			<Cookies />
		</Layout>
	)
}
export default IndexPage